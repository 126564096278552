@use 'global-styles/marble/base/base.scss';

.heroBanner {
	line-height: 0;
	max-height: calc(100vh - 150px);
	min-height: 32vw;
	overflow: hidden;
	position: relative;
}

.picture {
	display: block;
	height: 100%;
	width: 100%;
}

.text {
	backdrop-filter: blur(5px);
	background: rgba(0, 0, 0, 0.4);
	bottom: 0;
	color: var(--color-white);
	left: 0;
	padding: var(--spacing-xs) var(--spacing-component-margin);
	position: absolute;
	right: 0;
}

.title {
	font-family: var(--austin);
	font-weight: 500;
	margin-bottom: var(--spacing-micro);
	max-width: 100%;
	width: clamp(500px, 70%, 28em);
}

.description {
	line-height: 1.2;
	max-width: 100%;
	width: clamp(500px, 70%, 28em);
}

.linkList {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: var(--spacing-xxs);
	margin-top: var(--spacing-xs);
	@media screen and (min-width: base.$breakpoint-s) {
		flex-direction: row;
	}	
}

.toolTip {
	bottom: var(--spacing-xxs);
	left: var(--spacing-xxs);
	position: absolute;
}
