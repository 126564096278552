.linkButton {
	align-items: center;
	column-gap: 0.3em;
	display: inline-flex;
	line-height: 1;
	text-decoration: none;
	transition: all 0.2s;

	&:hover,
	&:focus {
		//Very specific underline effect
		box-shadow: 0 0.1em 0 0 currentColor;
		text-decoration: none;
	}
}

//Breathing room for icon.
.rightIcon {
	margin-right: 0.2em;
}

.leftIcon {
	margin-left: 0.2em;
}
