@use 'global-styles/marble/base/base.scss';

.backdrop {
	--button-height: 45px;
	backdrop-filter: blur(8px);
	background-color: var(--color-modal-backdrop);
	bottom: 0;
	display: flex;
	flex-direction: column;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: base.z('object-viewer');
}

.modal {
	background-color: var(--color-component-background-2);
	border-radius: 4px;
	color: var(--color-text-color);
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
	margin: 0 var(--spacing-micro);
	max-height: calc(100vh - var(--button-height)*2); //To make it look nice when there are no thumbnails
	overflow: hidden;
	padding-bottom: 0;
	@media (orientation: landscape) {
		margin: 0 var(--spacing-m);
	}
}

.buttons {
	display: flex;
	gap: var(--spacing-xxs);
	@media (orientation: landscape) {
		margin: var(--spacing-micro) var(--spacing-micro) 0;
	}
}

.arrow,
.close {
	@include base.button-reset();
	color: var(--color-white);
	height: var(--button-height);
	padding: var(--spacing-micro);
	width: var(--button-height);
	z-index: base.z('positive-default');

	&:active,
	&:hover {
		opacity: 0.8;
	}

	&:disabled {
		background-color: transparent;
		cursor: default;
		opacity: 0.2;
	}

	svg {
		height: 100%;
		width: 100%;
	}
}

.close {
	align-self: flex-end;
	display: block;
	margin-left: auto;
}

.arrow {
	bottom: var(--spacing-micro);
	width: auto;

	@media (orientation: landscape) {
		bottom: 50%;
		position: absolute;
	}
}

.forward {
	right: var(--spacing-xxs);
	transform: rotate(180deg);

	@media (orientation: landscape) {
		right: var(--spacing-micro);
	}
}

.back {
	left: var(--spacing-xxs);

	@media (orientation: landscape) {
		left: var(--spacing-micro);
	}
}
