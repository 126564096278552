.iframeWrapper {
	padding-top: var(--spacing-xs);

	// DS - Get ready for some janky numbers to get this iframe to fit and be responsive
	iframe {
		height: 1675px;
		width: 100%;
	
		@media screen and (min-width: 509px) {
			height: 1055px;
		}
	}
}
