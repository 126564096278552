@use 'global-styles/marble/base/base.scss';

.case {
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	max-width: 100%;
	overflow: hidden;
	padding-bottom: var(--spacing-m);
}

.caseTitle {
	margin: var(--spacing-xs) var(--spacing-component-margin);
}

.rowTitle {
	margin-left: var(--spacing-component-margin);
	margin-right: var(--spacing-component-margin);
}

.filterWrapper {
	margin-bottom: var(--spacing-s);
	overflow: auto;
	padding-left: var(--spacing-component-margin);
	@media (min-width: base.$breakpoint-m) {
		background-color: var(--color-component-background);
		bottom: 60px;
		margin-bottom: 0;
		position: fixed;
		width: 100%;
		z-index: base.z('kiosk-bar');
	}
}
