@use 'global-styles/marble/base/base.scss';

.personCard {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xxs);
	@media screen and (min-width: base.$breakpoint-m) {
		flex-direction: row;
		gap: var(--spacing-xs);
	}
}

.body {
	@media screen and (min-width: base.$breakpoint-m) {
		flex-basis: base.$line-length-max;
	}
}

.imageWrapper {
	background-color: var(--color-grey-050);
	flex-basis: auto;
	flex-shrink: 0;
	height: 200px;
	position: relative;
	width: 150px;
}

.name {
	font-size: var(--textsize-s);
}

.subHeading {
	color: var(--color-grey-700);
	margin-bottom: var(--spacing-micro);
}

.noImage {
	box-sizing: border-box; // needed incase this goes inside <details> (accordion), in which case it was forced to content-box
	height: 100%;
	padding: 12%;
	width: 100%;
}
