@use 'global-styles/marble/base/base.scss';

.promoContainer {
	display: grid;
	gap: var(--spacing-s);
}

.promo {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xxs);
	margin-left: auto;
	margin-right: auto;
	max-width: var(--productive-width-max);
	width: 100%;

	@media screen and (min-width: base.$breakpoint-m) {
		flex-direction: row;
		gap: var(--spacing-s);
	}

	&:nth-child(even) {
		@media screen and (min-width: base.$breakpoint-m) {
			flex-direction: row-reverse;
		}
	}
}

.imageWrapper {
	display: flex;
	overflow: hidden;
	position: relative;
	@media screen and (min-width: base.$breakpoint-m) {
		flex-basis: 50%;
	}
}

.copyWrapper {
	display: flex;
	@media screen and (min-width: base.$breakpoint-m) {
		flex-basis: 50%;
	}
}

.copy {
	align-items: flex-start;
	align-self: center;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.bodyText {
	font-size: var(--textsize-m);
	margin-bottom: var(--spacing-micro);
}

.figure {
	aspect-ratio: 16/9;
	display: block;
	line-height: 0;
	position: relative;
	width: 100%;
}

.title {
	font-size: var(--textsize-m);
	margin-bottom: 4px;
}
