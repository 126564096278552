@use 'global-styles/marble/base/base.scss';

.tooltipWrapper {
	--tooltip-icon-size: 24px;
	position: relative;
}

.tooltip {
	background: var(--color-site-background);
	border-radius: 4px;
	bottom: calc(var(--tooltip-icon-size) + var(--spacing-micro));
	filter: drop-shadow(2px 4px 6px var(--color-active-shadow));
	font-size: var(--textsize-xs);
	left: calc(-1/3 * var(--tooltip-icon-size));
	line-height: 1.4;
	margin: 0;
	opacity: 0;
	padding: var(--spacing-micro);
	position: absolute;
	transition: visibility 0.2s, opacity 0.2s linear;
	visibility: hidden;
	width: min(85vw, 500px);
	z-index: base.z('positive-default');

	&::after {
		background-color: var(--color-site-background);
		content: '';
		display: block;
		height: calc(2/3 * var(--tooltip-icon-size));
		left: calc(1/2 * var(--tooltip-icon-size));
		position: absolute;
		top: calc(100% - 10px);
		transform: rotate(45deg);
		width: calc(2/3 * var(--tooltip-icon-size));
	}

	a {
		font-weight: 500;
	}
}

.isVisible {
	opacity: 1;
	visibility: visible;
}

.icon {
	align-items: center;
	background-color: var(--color-white-opaque-85);
	border-radius: 100%;
	color: var(--color-link);
	cursor: pointer;
	display: inline-flex;
	font-family: var(--austin);
	font-size: var(--textsize-xs);
	height: var(--tooltip-icon-size);
	justify-content: center;
	text-align: center;
	width: var(--tooltip-icon-size);
}
