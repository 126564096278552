@use 'global-styles/marble/base/base.scss';
$breakpoint: base.$breakpoint-m;

.cardWrapper {
	container-name: searchCard;
	container-type: inline-size;
	list-style: none;
}

.card {
	column-gap: var(--spacing-xs);
	display: flex;
	flex-direction: column;
	flex-shrink: 0;

	@media screen and (min-width: $breakpoint) {
		flex-direction: row;
	}
}

.imageWrapper {
	align-items: center;
	align-self: center;
	aspect-ratio: 16 /9;
	background-color: var(--color-grey-050);
	justify-content: center;
	line-height: 0;
	margin-bottom: var(--spacing-xxs);
	padding: var(--spacing-xxs);
	position: relative;
	width: 100%;

	@media screen and (min-width: $breakpoint) {
		align-self: start;
		display: flex;
		flex-basis: 200px;
		flex-grow: 0;
		flex-shrink: 0;
		margin-bottom: 0;
		width: 200px;
	}
}

.image {
	box-shadow: 8px 8px 0 var(--color-grey-400);
	max-height: 100%;
	max-width: 100%;
	transition: all 0.2s;

	.imageWrapper:hover & {
		box-shadow: -8px -8px 0 var(--color-grey-400);
	}
}

.body {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
}

.heading {
	margin: 0;
}

.mainText {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-micro);
}

.subtitle {
	@include base.line-clamp(5);
	color: var(--color-grey-700);
	font-size: var(--textsize-xs);
	font-weight: 400;

	@media screen and (min-width: $breakpoint) {
		@include base.line-clamp(3);
	}
}

