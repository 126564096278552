.card {
	display: flex;
	flex-basis: 250px;
	flex-direction: column;
	flex-shrink: 0;
	gap: var(--spacing-micro);
}

.imageWrapper {
	align-items: center;
	background-color: var(--color-grey-050);
	display: flex;
	height: 320px;
	justify-content: center;
	line-height: 0;
	padding: var(--spacing-xxs);
	position: relative;
}

.image {
	box-shadow: 8px 8px 0 var(--color-grey-400);
	max-height: 100%;
	transition: all 0.2s;
	width: auto;

	.imageWrapper:hover & {
		box-shadow: -8px -8px 0 var(--color-grey-400);
	}
}

.body {
	display: flex;
	flex-direction: column;
}

.heading {
	font-size: var(--textsize-s);
	font-weight: 500;
}

.date {
	color: var(--color-grey-800);
	font-size: var(--textsize-xs);
}

.badgeWrapper {
	align-self: flex-start;
	margin-top: 0;
}
