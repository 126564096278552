.articleRule {
	border: 0;
	border-bottom: 1px solid var(--color-divider);
	height: 1px;
	margin: var(--spacing-s) auto 0 auto;
	width: 100%;
}

.citationFix {
	--spacing-component-margin: 0;
}

.seriesAbout {
	color: var(--color-text);
	font-size: var(--textsize-xs);
}

.xsText {
	font-size: var(--textsize-xs);
}
