@use 'global-styles/marble/base/base.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-micro);
	margin: 0 auto var(--spacing-xxs);
	padding: 0;

	&.clampedWidth {
		padding: 0 var(--spacing-component-margin);
	}
}

.titleAndLinkWrapper {
	display: flex;
	flex-direction: row;
	gap: var(--spacing-xxs);
	justify-content: space-between;
}

.description {
	color: var(--color-text);
	display: block;
	font-size: var(--textsize-m);
	width: min(700px, 100%);
}

.cta {
	align-self: flex-end;
	flex-basis: auto;
	flex-shrink: 0;
	margin-top: 4px;
	text-align: right;
}
