.title {
	font-family: var(--austin);
	font-weight: 500;
	margin-bottom: var(--spacing-micro);
}

.regularTitle,
.longTitle {
	font-size: var(--textsize-xl);
	line-height: 1.05;
}

.veryLongTitle {
	font-size: var(--textsize-l);
	line-height: 1.2;
}
