.grid {
	color: var(--color-text);
	column-gap: var(--spacing-xxs);
	display: grid;
	grid-template-columns: repeat(2, auto);
	row-gap: 4px;
}

.label {
	color: var(--color-text-header);
	font-weight: 500;
}
