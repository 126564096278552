@use 'global-styles/marble/base/base.scss';

.content {
	--max-text-width: 575px;
	background-color: var(--color-site-background);
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xxs);
	overflow: hidden;

	@media (orientation: landscape) {
		align-items: center;
		flex-direction: row-reverse;
		gap: 0;
		height: 100%;
		justify-content: space-between;
	}
}

.imageWrapper {
	align-items: center;
	background-color: var(--color-component-background);
	display: flex;
	flex-basis: 100%;
	flex-direction: column;
	gap: 4px;
	height: 100%;
	justify-content: center;
	max-height: 33vh;
	padding: 8px;

	@media (orientation: landscape) {
		max-height: none;
	}
}

.image {
	align-self: center;
	height: auto;
	margin: auto 0;
	max-height: 80%; //TODO find a better way to handle this
	max-width: 100%;
	min-width: 0;
}

.noImage {
	height: 10em;
	width: auto;
}

.textWrapper {
	--fade-out-size: var(--spacing-xs);
	overflow: hidden;
	padding-bottom: 0;
	position: relative;

	@media (orientation: landscape) {
		flex-basis: 40%;
		flex-grow: 0;
		flex-shrink: 0;
		height: 100%;
		max-width: var(--max-text-width);
		
		&:empty {
			flex-basis: 0;
		}
	}

	&::before,
	&::after {
		content: '';
		height: var(--fade-out-size);
		left: 0;
		position: absolute;
		right: 0;
	}
	
	&::before {
		background: linear-gradient(180deg, var(--color-site-background) 0%, var(--color-site-background) 10%, transparent 100%);
		top: 0;
	}

	&::after {
		background: linear-gradient(180deg, transparent 0%, var(--color-site-background) 90%, var(--color-site-background) 100%);
		bottom: 0;
	}
}

.text {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	font-size: var(--textsize-xs);
	gap: var(--spacing-xs);
	height: 100%;
	overflow: auto;
	padding: var(--fade-out-size);
}

.attribution {
	display: flex;
	flex-direction: column;
	font-size: var(--textsize-xs);
	font-weight: 500;
	gap: 4px;
}
