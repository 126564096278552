@use 'global-styles/marble/base/base.scss';

.skipToMainContent {
	display: block;
	left: -100%;
	pointer-events: none; // disables clicks on it
	position: absolute;
	top: 0;

	&:focus {
		left: 50%;
		margin: var(--spacing-micro);
		pointer-events: all;
		transform: translateX(-50%);
		z-index: base.z('skip-to-content');
	}
}
