@use 'sass:math';
@use 'global-styles/marble/base/base.scss';

.card {
	display: flex;
	flex-basis: 0;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
}

.sideBySide {
	flex-direction: row;
	gap: var(--spacing-micro);
}

.imageWrapper {
	aspect-ratio: 16 / 9;
	flex-basis: auto;
	flex-shrink: 0;
	line-height: 0;
	position: relative;
	width: 100%;

	.sideBySide & {
		aspect-ratio: auto;
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		height: 0;
		padding-bottom: 28.5%; //TODO make this cleaner and better.
	}
}

.body {
	display: flex;
	flex-basis: 100%;
	flex-direction: column;
	font-size: var(--textsize-xs);
	padding: var(--spacing-micro) 0;

	.sideBySide & {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		padding-top: 0;
	}
}

.eyebrow {
	@include base.typography-tag();
	color: var(--color-text);
	display: block;
	font-weight: 500;
	line-height: 1;
	margin-bottom: var(--spacing-micro);

	&:empty {
		display: none;
	}
}

.description {
	margin-bottom: var(--spacing-micro);
}

.heading {
	@include base.typography-h4;
	margin-bottom: var(--spacing-micro);
}

.badges {
	bottom: var(--spacing-micro);
	display: flex;
	gap: 4px;
	left: var(--spacing-micro);
	position: absolute;
	z-index: base.z('positive-default');
}
