@use 'global-styles/marble/base/base.scss';

$text-color: var(-color-grey-900);
$button-grid-width: var(--spacing-component-margin);
$indigo-background: var(--color-blue-600-75);
$orange-backgroud: var(--color-orange-600-75);
$blue-background: var(--color-blue-500-75);
$red-background: var(--color-red-600-75);
$yellow-background: var(--color-yellow-600-75);
$green-background: var(--color-green-600-75);

@mixin svg-arrows {
	border-radius: 50%;
	color: #333;
	height: 48px;
	width: 48px;

	&:active {
		background-color: #333;
		color: #fff;
	}

	@media screen and (min-width: base.$breakpoint-m) {
		&:hover {
			background-color: #333;
			color: #fff;
		}
	}
}

.indigoBackground {
	background-color: $indigo-background;
}

.orangeBackground {
	background-color: $orange-backgroud;
}

.blueBackground {
	background-color: $blue-background;
}

.redBackground {
	background-color: $red-background;
}

.yellowBackground {
	background-color: $yellow-background;
}

.greenBackground {
	background-color: $green-background;
}

.inlineAnnouncement {
	--color-text: var(--color-grey-900);
	color: var(--color-text);
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto;
	padding: var(--spacing-component-margin);
	width: 100%;

	@media screen and (min-width: base.$breakpoint-m) {
		grid-template-columns: $button-grid-width 1fr 2fr $button-grid-width;
		padding: var(--spacing-xxs) 0;
	}
}

.eyebrowContainer {
	grid-column: 1 / span 2;
	grid-row: 1;
	margin-bottom: var(--spacing-micro);

	@media screen and (min-width: base.$breakpoint-m) {
		grid-column: 2 / span 2;
		grid-row: 1;
	}
}

.titleContainer {
	grid-column: 1 / span 2;
	grid-row: 2;

	@media screen and (min-width: base.$breakpoint-m) {
		grid-column: 2;
		grid-row: 2;
		padding-right: var(--spacing-s);
	}
}

.eyebrow {
	font-size: var(--textsize-xxs);
	font-weight: 500;
	text-transform: uppercase;
}

.announcementContainer {
	grid-column: 1 / span 2;
	grid-row: 3;

	@media screen and (min-width: base.$breakpoint-m) {
		grid-column: 3;
		grid-row: 2;
		justify-self: stretch;
	}
}

.announcementText {
	font-size: var(--textsize-s);
	margin-bottom: var(--spacing-xxs);
}

.linkContainer {
	margin-bottom: var(--spacing-xxs);
}

.leftButtonContainer {
	grid-column: 1;
	grid-row: 4;

	svg {
		@include svg-arrows();
	}

	@media screen and (min-width: base.$breakpoint-m) {
		align-self: center;
		grid-column: 1;
		grid-row: 1 / span 2;
		text-align: center;
		width: 100%;
	}
}

.rightButtonContainer {
	grid-column: 2;
	grid-row: 4;
	justify-self: end;
	text-align: right;

	svg {
		@include svg-arrows();
	}

	@media screen and (min-width: base.$breakpoint-m) {
		align-self: center;
		grid-column: 4;
		grid-row: 1 / span 2;
		text-align: center;
		width: 100%;
	}
}

.announcementButton {
	background: none;
	border: 0;
	padding: 0;
}
