$exhibition-promo-image-width: 600px;

.pastExhibitionCard {
	max-width: $exhibition-promo-image-width;
}

.sectionSubtitles {
	margin-bottom: var(--spacing-xxs);
	margin-left: var(--spacing-component-margin);
}
