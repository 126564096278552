@use 'global-styles/marble/base/base.scss';

.header {
	padding: var(--spacing-xs) var(--spacing-component-margin) 0;
}

.content {
	border-bottom: 1px solid var(--color-divider);
	display: flex;
	flex-direction: column;
	gap: var(--spacing-micro);
	padding-bottom: var(--spacing-xs);
}

.description {
	font-size: var(--textsize-l);
	font-weight: 500;
	max-width: 28em;
}

.heading {
	max-width: 16em;
	word-break: break-word;
	@media only screen and (min-width: base.$breakpoint-m) {
		flex-grow: 1;
	}
}

.headingContainer {
	display: flex;
	flex-direction: column-reverse;
	gap: var(--spacing-xs);
	justify-content: space-between;

	@media only screen and (min-width: base.$breakpoint-m) {
		align-items: flex-start;
		flex-direction: row;
	}
}

.languageFilterContainer {
	@media only screen and (min-width: base.$breakpoint-m) {
		flex-basis: 0;
		margin-top: 0;
	}
}

.linkList {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: var(--spacing-xxs);
	margin-top: var(--spacing-xxs);
	@media screen and (min-width: base.$breakpoint-s) {
		flex-direction: row;
	}	
}

.linkItem {
	line-height: 1;
}
