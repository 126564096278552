@use 'global-styles/marble/base/base.scss';
@use 'global-styles/marble/base/sizes.scss';

.pills {
	margin-bottom: var(--spacing-xxs);
}

.resultsCountSection {
	align-items: start;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: var(--spacing-xxs);
	row-gap: var(--spacing-micro);

	@media only screen and (min-width: sizes.$breakpoint-m) {
		align-items: center;
		flex-direction: row;
		position: relative;
	}
}

.resultCount {
	color: var(--color-text-header);
	font-size: var(--textsize-s);
	font-weight: 500;
}

.sortByTitle {
	color: var(--color-text-header);
	font-weight: 400;
}

.sortControl {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: var(--spacing-micro);
	max-width: 340px;
}

.pressReleasesContainer {
	align-items: start;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-xs);
}

.filterHeading {
	border-bottom: 1px solid var(--color-border);
	margin-bottom: var(--spacing-xxs);
	padding-bottom: var(--spacing-xxs);
}

.checkboxGroupHeading {
	align-items: center;
	display: flex;
	gap: 8px;
	height: 1.4em;
}

.checkboxGroups {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-xxs);
	padding-bottom: var(--spacing-xs);
}
