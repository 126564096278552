.message {
	align-items: baseline;
	background: var(--color-grey-050);
	border: 1px solid var(--color-border);
	border-radius: 4px;
	color: var(--color-grey-900);
	font-size: var(--textsize-xxs);
	gap: 4px;
	justify-content: center;
	padding: 6px;
	text-align: center;
}

.icon {
	height: 0.8em;
	margin-bottom: -1px;
}
